<template>
  <div>
    <v-card>
      <v-card-title>Inventory Information</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-select
                append-icon="mdi-chevron-down"
                :items="models"
                item-text="name"
                item-value="name"
                label="Model"
                return-object
                outlined
                clearable
                clear-icon="mdi-close-circle-outline"
                background-color="selects"
                @change="handleGetVariant"
                v-model="form.model"
                :rules="[(value) => !!value || 'Model is required']"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                append-icon="mdi-chevron-down"
                :items="vehicles"
                item-text="name"
                item-value="name"
                return-object
                label="Vehicle"
                outlined
                clearable
                clear-icon="mdi-close-circle-outline"
                background-color="selects"
                v-model="form.vehicle"
                @change="handleGetVariantColor"
                :rules="[(value) => !!value || 'Vehicle is required']"
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.name }} - {{ data.item.year ? data.item.year.name : '' }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.name }} - {{ data.item.year ? data.item.year.name : '' }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                append-icon="mdi-chevron-down"
                :items="colors"
                item-text="name"
                item-value="id"
                label="Color"
                return-object
                outlined
                clearable
                clear-icon="mdi-close-circle-outline"
                background-color="selects"
                v-model="form.color"
                :rules="[(value) => !!value || 'Color is required']"
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.color.name ? data.item.color.name : '' }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.color.name ? data.item.color.name : '' }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <dealer :dealer="form.dealer" />
            </v-col>
          </v-row>
        </v-form>
        <div class="d-flex justify-end mb-6 mt-10" flat tile>
          <v-btn
            large
            exact
            color="green darken-1"
            class="ma-1 white--text"
            :loading="loadingSave"
            :disabled="loadingSave || loadingSaveAndExit"
            @click="validate(false)"
          >
            <v-icon left>mdi-content-save</v-icon>
            Save
          </v-btn>
          <v-btn
            large
            exact
            color="green lighten-1"
            class="ma-1 white--text"
            @click="validate(true)"
            :loading="loadingSaveAndExit"
            :disabled="loadingSave || loadingSaveAndExit"
          >
            <v-icon left>mdi-content-save</v-icon>
            Save and Exit
          </v-btn>
          <v-btn large exact color="warning" class="ma-1 white--text" :to="{ name: 'inventories' }">
            <v-icon left>mdi-close</v-icon>
            Cancel
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Dealer from "@/components/Dealer/Dealer";
import { find } from "lodash";

export default {
  components: {
    Dealer
  },
  props: {
    details: {
      required: false,
      type: Object,
      default() {
        return {
          variant: null,
          dealer: null,
          quantity: null,
        };
      },
    },
  },
  data() {
    return {
      variant: null,
      quantity: null,
      valid: true,
      loadingSave: false,
      loadingSaveAndExit: false,
      colors: [],
      form: {
        model: null,
        year: null,
        color: null,
        vehicle: null,
      },
      vehicles: [],
    };
  },
  async mounted() {
    await this.setFormData();
  },
  computed: {
    ...mapGetters({
      models: "models/GET_MODELS",
      years: "years/GET_YEARS",
      variants: "cars/GET_VARIANTS",
      dealers: "dealers/GET_DEALERS",
      dealer: "dealer/GET_DEALER",
      variantColors: "variantColors/GET_VARIANT_COLORS"
    }),
  },
  methods: {
    ...mapActions({
      getVariants: "cars/list",
      getYears: "years/list",
      getModels: "models/list",
      save: "inventories/save",
      getDealers: "dealers/getDealers",
      getVariantColors: "variantColors/list"
    }),

    async setFormData() {
      await this.getYears();
      await this.getModels();
      await this.getDealers();
      if (this.$route.params.id) {
        this.form.quantity = this.details.quantity;
        this.form.dealer = this.details.dealer_id;
        if (this.details.model_name) {
          this.form.model = find(this.models, { name: this.details.model_name });
          this.form.color = find(this.colors, { name: this.details.color_name });
          this.form.year = find(this.years, { code: this.details.model_year });
          this.form.dealer = find(this.dealers, { name: this.details.dealer_name });
          await this.handleGetVariant();
          this.form.vehicle = find(this.vehicles, { name: this.details.variant_name });
        }
      }
    },

    async validate(isExit) {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      if (isExit) {
        this.loadingSaveAndExit = true;
      } else {
        this.loadingSave = true;
      }

      await this.saveInventory(isExit);
    },

    async handleGetVariant() {
      await this.getVariants({ model: this.form.model.id }).then((data) => {
        this.vehicles = this.variants.data;
      });
    },

    async handleGetVariantColor(item) {
      await this.getVariantColors({ variant_id : item.id });
      this.form.year = item.year;
      this.colors = this.variantColors.data;
    },

    async saveInventory(isExit) {
      const id = this.$route.params.id ? this.$route.params.id : null;

      this.loadingSaveAndExit = false;
      this.loadingSave = false;
      const data = {
        variant_code: this.form.vehicle.code,
        variant_name: this.form.vehicle.name,
        variant_id: this.form.vehicle.id,
        model_name: this.form.model.name,
        color_name: this.form.color.color ? this.form.color.color.name : null,
        color_code: this.form.color.color ? this.form.color.color.code : null,
        color_id: this.form.color.color ? this.form.color.color.id : null,
        model_year: this.form.year.code,
        dealers: this.dealer,
        quantity: parseInt(this.form.quantity ? this.form.quantity : 1),
      };
      await this.save({
        id,
        data,
      });

      this.loadingSaveAndExit = false;
      this.loadingSave = false;

      if (!id) {
        this.$refs.form.reset();
      }

      if (isExit) {
        this.$router.push({ name: "inventories" });
      }
    },
  },
};
</script>

<style></style>
